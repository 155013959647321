import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { useNavigate } from 'react-router-dom';
import { useAlert, useHttpClient, useHelper } from '../../../../modules/services/Bundle';
import { RequestMethod } from '../../../../modules/services/core/_enums';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { ptBR } from '@mui/x-date-pickers/locales';
import br from 'dayjs/locale/pt-br';
import dayjs from 'dayjs';
import { KTSVG } from '../../../../../_metronic/helpers';
import { IconButton } from '@mui/material';
import { ModalAddCliente } from './ModalAddCliente';
import { useAuth } from '../../../../modules/auth';
import { ModalLogConsultoria } from './ModalLogConsultoria';


interface Column {
  id: 'IdEmpresaCliente' | 'DominioEmpresaCliente' | 'RazaoSocialEmpresaCliente' | 'NomeResponsavel' | 'DataCadastroString' | 'Ativo' | 'CaracteristicasDoNegocio';
  label: string;
  minWidth?: number;
  align?: 'center';
  format?: (value: number) => string;
}

interface Filtros {
  RazaoSocialEmpresaCliente: string,
  DataCadastroInicio: string,
  DataCadastroFim: string,
}

const columns: readonly Column[] = [
  { 
    id: 'DominioEmpresaCliente', 
    label: 'DOMÍNIO'
  },
  { 
    id: 'RazaoSocialEmpresaCliente', 
    label: 'RAZÃO SOCIAL'
  },
  { 
    id: 'NomeResponsavel', 
    label: 'RESPONSÁVEL'
  },
  {
    id: 'DataCadastroString',
    label: 'DATA DE CRIAÇÃO'
  },
  {
    id: 'Ativo',
    label: 'ATIVO'
  },
];

interface Data {
  Id?: number
  IdEmpresaCliente: number;
  DominioEmpresaCliente: string;
  RazaoSocialEmpresaCliente: string;
  NomeResponsavel: string;
  IsResponsavel: boolean;
  DataCadastroString: string;
  Ativo: boolean;
  EmailResponsavel: string
  IdSegmento: number
  NomeSegmento: string
  PorteEmpresa: number
  QtdFuncionarios: number
  QtdMaximaCargos: number
  CaracteristicasDoNegocio: string
  UtilizaAvaliador: boolean
  PermiteEditarDescricao: boolean
  EmailRepresentante: string
  EmpresaDominio: []
  EmpresaClienteLogo: string
}

function createData(
  Id: number | undefined,
  IdEmpresaCliente: number,
  DominioEmpresaCliente: string,
  RazaoSocialEmpresaCliente: string,
  NomeResponsavel: string,
  IsResponsavel: boolean,
  DataCadastroString: string,
  Ativo: boolean,
  EmailResponsavel: string,
  IdSegmento: number,
  NomeSegmento: string,
  PorteEmpresa: number,
  QtdFuncionarios: number,
  QtdMaximaCargos: number,
  CaracteristicasDoNegocio: string,
  UtilizaAvaliador: boolean,
  PermiteEditarDescricao: boolean,
  EmailRepresentante: string,
  EmpresaDominio: [],
  EmpresaClienteLogo: string
): Data {
  return { Id, IdEmpresaCliente, DominioEmpresaCliente, RazaoSocialEmpresaCliente, NomeResponsavel, IsResponsavel, DataCadastroString, Ativo, EmailResponsavel, IdSegmento, NomeSegmento, PorteEmpresa, QtdFuncionarios, QtdMaximaCargos, CaracteristicasDoNegocio, UtilizaAvaliador, PermiteEditarDescricao, EmailRepresentante, EmpresaDominio, EmpresaClienteLogo};
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
}

type Props = {
  atualizarTable: boolean,
  setAtualizarTable: Function
}

const TableClienteConsultoria = ({atualizarTable, setAtualizarTable}: Props) => {  
  const httpClient = useHttpClient()
  const helper = useHelper()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>('RazaoSocialEmpresaCliente')
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [total, setTotal] = React.useState(0)
  const [rows, setRows] = React.useState<Data[]>([])   
  const [currentCliente, setCurrentCliente] = React.useState<Data>()   
  const alert = useAlert()
  const {auth} = useAuth()

  const [razaoSocial, setRazaoSocial] = React.useState<any>(null)
  const [dataCadastroStart, setDataCadastroStart] = React.useState<any>(null)
  const [dataCadastroEnd, setDataCadastroEnd] = React.useState<any>(null)
  const [abrirModalLog, setAbrirModalLog] = React.useState(false)
  const [abrirModal, setAbrirModal] = React.useState(false)
  
  function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, onRequestSort } =
      props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };
  
    return (
      <TableHead >
        <TableRow className='table-header'>
          {columns.map((columns) => (
            <TableCell
            className='table-cell'
            key={columns.id}
            align={columns.align ? 'right' : 'left'}
          >
            <TableSortLabel
              className='fw-bolder'
              active={orderBy === columns.id}
              direction={orderBy === columns.id ? order : 'asc'}
              onClick={createSortHandler(columns.id)}
            >
              {columns.label}
              {orderBy === columns.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
          ))}
            <TableCell
              className='fw-bolder table-cell'
              align='center'
            >
              AÇÕES
            </TableCell> 
        </TableRow>
      </TableHead>
    );
  }

  const resquestTable = (pageRq: number, orderByRq: string, orderRq: string, rowsPerPageRq: number, Filtros: any = null) => {
    
      httpClient.request({
        method: RequestMethod.POST,
        endpoint: '/DescricaoDeCargo/Consultoria/Listar',
        data: {
          Order: orderRq,
          OrderBy: orderByRq,
          Page: pageRq,
          RowsPerPage: rowsPerPage,
          Filters: Filtros
        }
      }).then((result) => {
          const newRows = result.payload.data.map((data: Data) => {
            return createData(
              data.Id,
              data.IdEmpresaCliente, 
              data.DominioEmpresaCliente, 
              data.RazaoSocialEmpresaCliente, 
              data.NomeResponsavel, 
              data.IsResponsavel, 
              data.DataCadastroString, 
              data.Ativo, 
              data.EmailResponsavel, 
              data.IdSegmento, 
              data.NomeSegmento,
              data.PorteEmpresa, 
              data.QtdFuncionarios, 
              data.QtdMaximaCargos,
              data.CaracteristicasDoNegocio,
              data.UtilizaAvaliador,
              data.PermiteEditarDescricao,
              data.EmailRepresentante,
              data.EmpresaDominio,
              data.EmpresaClienteLogo
            )
          })
          setRows([...newRows])
          setTotal(result.payload.total)
      })
    
  }

  React.useEffect(() => {
      resquestTable(page, orderBy, order, rowsPerPage);
  },[])

  React.useEffect(() => {
    if (atualizarTable) {
      resquestTable(page, orderBy, order, rowsPerPage);
      setAtualizarTable(false)
    }
  },[atualizarTable])


  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    resquestTable(page, property, isAsc ? 'desc' : 'asc', rowsPerPage);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.RazaoSocialEmpresaCliente);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    resquestTable(newPage, orderBy, order, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    resquestTable(page, orderBy, order, parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rows.length;

  const atualizarDados = () => {
    resquestTable(page, orderBy, order, rowsPerPage)
  }

  const onClickBuscar = () => {
    
    const filtros:Filtros = {
      RazaoSocialEmpresaCliente: razaoSocial,
      DataCadastroInicio: dataCadastroStart,
      DataCadastroFim: dataCadastroEnd
    }
    
    resquestTable(page, orderBy, order, rowsPerPage,filtros);
    handleClose()
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
      setDataCadastroStart(null)
      setDataCadastroEnd(null)
      setRazaoSocial(null)
  };

  const onChangeDatePickerStart = (e:any) => {
    setDataCadastroStart(dayjs(e.$d).format('YYYY-MM-DD'))
  };

  const onChangeDatePickerEnd = (e:any) => {
    setDataCadastroEnd(dayjs(e.$d).format('YYYY-MM-DD'))
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  

  return (
    <>
      {(auth?.roles.find((x: string) => x === 'Master') !== undefined ||
        auth?.roles.find((x: string) => x === 'Administrador') !== undefined || 
        (auth?.permissoes.find((x: string) => x === '54522E1985DA86BF93D683BAEB093EC7') !== undefined)) && (
          <ModalAddCliente 
            setAtualizarTable={setAtualizarTable}
            abrirModal={abrirModal}
            setAbrirModal={setAbrirModal}
            atualizarDados={atualizarDados}
            currentCliente={currentCliente}
          />
      )}
      
      {abrirModalLog && (
      <ModalLogConsultoria
        descricaoTipo="Logs de Consultoria"
        abrirModal={abrirModalLog}
        setAbrirModal={setAbrirModalLog}
        color="" // Ajuste conforme necessário
        titleColor="" // Ajuste conforme necessário
      />)}

      <form className='form'>

          <div className='d-flex justify-content-end mb-2'>

            <div>
              <Button type='button' className='btn btn-light-primary' aria-describedby={id} onClick={handleClick}>
                <i className="fs-2 fas fa-arrow-down-wide-short"></i>
                Filtros
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className="popover-filtro-color-bg"
              >
                
                  <div className='bg-body'>
                    {/* begin::Header */}
                    <div className='px-7 py-5'>
                      <div className='fs-5 text-dark fw-bolder'>Opções</div>
                    </div>
                    {/* end::Header */}

                    {/* begin::Separator */}
                    <div className='separator border-gray-200'></div>
                    {/* end::Separator */}

                    {/* begin::Content */}
                    <div className='px-7 py-5' >

                      {/* begin::Input group */}
                      <div className='mb-10'>
                        <label className='form-label fs-6 fw-bold'>Nome da Empresa:</label>
                        <input 
                          type='text'
                          onChange={e => setRazaoSocial(e.target.value)} 
                          placeholder='Nome da Empresa' 
                          className={`form-control form-control-lg form-control-solid`}
                        ></input>
                      </div>
                      {/* end::Input group */}

                      {/* begin::Input group */}
                      <div className='mb-10'>
                        <label className='form-label fs-6 fw-bold'>Data de Criação:</label>
                        <div className='d-flex justify-content-between'>
                          <div className='col-lg-5'>
                            <label className='form-label fs-6 fw-semibold'>Início:</label>
                            <LocalizationProvider adapterLocale={br.name} dateAdapter={AdapterDayjs}>
                              <DatePicker 
                                format="DD/MM/YYYY"
                                localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                                onChange={(e) => onChangeDatePickerStart(e)} 
                                className='date-picker-color w-100'
                              />
                            </LocalizationProvider>
                          </div>
                          <div className='col-lg-1 d-flex justify-content-center align-items-center mt-5'>
                            <KTSVG path="/media/icons/duotune/arrows/arr010.svg" className="svg-icon-muted svg-icon-2hx text-gray-800" />
                          </div>
                          <div className='col-lg-5'>
                            <label className='form-label fs-6 fw-semibold'>Fim:</label>
                            <LocalizationProvider adapterLocale={br.name} dateAdapter={AdapterDayjs}>
                              <DatePicker 
                                format="DD/MM/YYYY"
                                localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                                onChange={(e) => onChangeDatePickerEnd(e)} 
                                className='date-picker-color w-100'
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                      {/* end::Input group */}

                      {/* begin::Actions */}
                      <div className='d-flex justify-content-end'>
                        <button
                          type='button'
                          className='btn btn-light btn-active-light-primary fw-bold me-5 px-6'
                          onClick={handleClose}
                        >
                          Cancelar
                        </button>
                        <button
                          type='button'
                          className='btn btn-primary fw-bold px-6'
                          onClick={() => onClickBuscar()}
                        >
                          Buscar
                        </button>
                      </div>
                      {/* end::Actions */}
                    </div>
                    {/* end::Content */}
                  </div>
              </Popover>
            </div>
          </div>

          <Box sx={{ width: '100%' }}>
            <Paper className='table-border ' sx={{ width: '100%', mb: 2 }}>
                     
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <TableRow
                          className='table-row'
                          hover
                          tabIndex={-1}
                          key={index}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell className='table-cell' align="left">{row.EmpresaDominio?.join(', ') ?? ""}</TableCell>
                          <TableCell className='table-cell' align="left">{row.RazaoSocialEmpresaCliente}</TableCell>
                          <TableCell className='table-cell' align="left">{row.NomeResponsavel}</TableCell>
                          <TableCell className='table-cell' align="left">{row.DataCadastroString}</TableCell>
                          <TableCell className='table-cell' align="left">{row.Ativo ? 'Sim' : 'Não'}</TableCell>
                          <TableCell className='table-cell' align="center">

                            {row.IsResponsavel && (
                              <>
                                {row.Ativo ? (
                                  <>
                                  <IconButton 
                                    className='icon-button-dark-hover' 
                                    title='Finalizar projeto'
                                    onClick={() => {
                                      alert.createDialog({
                                        html: `Realmente deseja finalizar o projeto desse cliente?`,
                                        confirmAction: async () => {
                                          httpClient.request({
                                            method: RequestMethod.POST,
                                            endpoint: '/DescricaoDeCargo/Consultoria/FinalizarCliente',
                                            queryObject: {
                                              idCliente: row.IdEmpresaCliente
                                            }
                                          }).then((response) => {
                                            if(response.success){
                                              resquestTable(page, orderBy, order, rowsPerPage);
                                            }
                                          })
                                        }
                                      })
                                    }} color="primary" aria-label="upload picture" component="label"
                                    >
                                      <i className="fs-6 text-gray-800 m-0 p-0 bi bi-building-fill-check"></i>
                                  </IconButton>

                                  <IconButton 
                                      className='icon-button-dark-hover' 
                                      title='Encerrar projeto'
                                      onClick={() => {
                                        alert.createDialog({
                                          html: `Realmente deseja encerrar o projeto desse cliente?`,
                                          confirmAction: async () => {
                                            httpClient.request({
                                              method: RequestMethod.POST,
                                              endpoint: '/DescricaoDeCargo/Consultoria/EncerrarCliente',
                                              queryObject: {
                                                idCliente: row.IdEmpresaCliente
                                              }
                                            }).then((response) => {
                                              if(response.success){
                                                resquestTable(page, orderBy, order, rowsPerPage);
                                              
                                              }
                                            })
                                          }
                                        })
                                      }} color="primary" aria-label="upload picture" component="label"
                                      >
                                        <i className="fs-6 text-gray-800 m-0 p-0 bi bi-building-fill-x"></i>
                                    </IconButton>
                                      <IconButton 
                                        className={`text-gray-800`}
                                        title='Editar'
                                        onClick={() => { 
                                            setCurrentCliente(row); 
                                            setAbrirModal(true); 
                                        }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                        </svg>
                                      </IconButton>
                                      <IconButton 
                                        className={`text-gray-800`}
                                        title='Ver Logs'
                                        onClick={() => {   // Defina a descrição ou outro parâmetro necessário
                                          setAbrirModalLog(true); 
                                        }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="svg-icon-muted svg-icon-2hx" viewBox="0 0 16 16">
                                        <path d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm8 1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zm-2 2a.5.5 0 0 1 .5.5v.5H6a.5.5 0 0 0-.5.5V6H4a.5.5 0 0 1 0-1h.5V4a2 2 0 0 1 2-2h3a.5.5 0 0 1 .5.5V3.5z"/>
                                        </svg>
                                      </IconButton>
                                  </>
                                  
                                ):(
                                  <IconButton 
                                    className='icon-button-dark-hover' 
                                    title='Baixar Descricao'
                                    onClick={() => {
                                      alert.createDialog({
                                        html: `Realmente deseja baixar as descrições desse cliente?`,
                                        confirmAction: async () => {
                                          httpClient.request({
                                            method: RequestMethod.POST,
                                            endpoint: '/DescricaoDeCargo/Consultoria/BaixarDescricaoCliente',
                                            queryObject: {
                                              idCliente: row.IdEmpresaCliente
                                            }
                                          }).then((response) => {
                                            if(response.success && response.payload){
                                              helper.downloadFile(response.payload);
                                              resquestTable(page, orderBy, order, rowsPerPage);
                                            }
                                          })
                                        }
                                      })
                                    }} color="primary" aria-label="upload picture" component="label"
                                    >
                                      <i className="fs-6 text-gray-800 m-0 p-0 bi bi-building-down"></i>
                                  </IconButton>
                                )}
                              </>
                            )}

                            
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows == 0 && (
                      <TableRow
                      >
                        <TableCell className='table-cell bg-body text-center' colSpan={6}>
                          Nenhum registro encontrado
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className='table-footer'
                labelRowsPerPage="linhas por página"
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
      </form>
    </>    
  )
}

export {TableClienteConsultoria}