import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { useHttpClient } from '../../../../modules/services/Bundle';
import { RequestMethod } from '../../../../modules/services/core/_enums';

interface Column {
  id: 'acao' | 'data' | 'Responsavel' | 'IdClienteConsultoriaDescricao';
  label: string;
  minWidth?: number;
  align?: 'center';
}

interface Data {
  acao: string;
  data: string;
  NomeResponsavel: string;
  NomeCliente: string;
}

const columns: readonly Column[] = [
  { id: 'acao', label: 'Ação', align: 'center' },
  { id: 'data', label: 'Data', align: 'center' },
  { id: 'Responsavel', label: 'Responsável', align: 'center' },
  { id: 'IdClienteConsultoriaDescricao', label: 'ID Cliente/Consultoria', align: 'center' }
];

function createData(
  acao: string,
  data: string,
  NomeResponsavel: string,
  NomeCliente: string
): Data {
  return { acao, data, NomeResponsavel, NomeCliente };
}

const TableLogConsultoriaProjeto = () => {
  const httpClient = useHttpClient();
  const [rows, setRows] = React.useState<Data[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [total, setTotal] = React.useState(0);

  const fetchLogData = (page: number, rowsPerPage: number) => {
    httpClient.request({
      method: RequestMethod.POST,
      endpoint: '/DescricaoDeCargo/Consultoria/ListarLogConsultoriaProjeto',
      data: {
        Page: page,
        RowsPerPage: rowsPerPage
      }
    }).then((result) => {
      console.log('Dados recebidos do backend:', result); // Adicione este console.log para ver os dados brutos
      const newRows = result.payload.data.map((log: Data) => {
        return createData(
          log.acao, 
          log.data, // Formata a data para exibição
          log.NomeResponsavel, 
          log.NomeCliente
        );
      });
      setRows(newRows);
      setTotal(result.payload.total);
      console.log('Dados formatados para a tabela:', newRows); // Adicione este console.log para ver os dados formatados
    }).catch(error => {
      console.error('Erro ao buscar dados:', error); // Adicione este console.log para capturar possíveis erros
    });
  };

  
  React.useEffect(() => {
    console.log('Chamando fetchLogData com página:', page, 'e linhas por página:', rowsPerPage);
    fetchLogData(page, rowsPerPage);
  }, [page, rowsPerPage]);
  

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, index) => {
                    console.log('Linha da tabela:', row); // Adicione este console.log para ver cada linha renderizada
                    return (
                    <TableRow hover key={index}>
                        <TableCell align="center">{row.acao}</TableCell>
                        <TableCell align="center">{row.data}</TableCell>
                        <TableCell align="center">{row.NomeResponsavel}</TableCell>
                        <TableCell align="center">{row.NomeCliente}</TableCell>
                    </TableRow>
                    );
                })}
            </TableBody>

          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export { TableLogConsultoriaProjeto };
