import { KTSVG } from '../../../../../_metronic/helpers'
import { TableLogConsultoriaProjeto } from './TableLogConsultoriaProjeto'

type Props = {
  descricaoTipo: string
  abrirModal: boolean
  setAbrirModal: (value: boolean) => void // Atualizado para garantir que a função recebe um booleano
  color: string
  titleColor: string
}

const ModalLogConsultoria = ({ descricaoTipo, abrirModal, setAbrirModal, titleColor, color }: Props) => {

  return (
    <div className={`modal show fade`} tabIndex={-1} id="kt_modal_1" style={{ display:"block" }}>
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header" style={{ backgroundColor: color }}>
            <h5 style={{ color: titleColor }} className="modal-title">{descricaoTipo}</h5>
            <div
              onClick={() => setAbrirModal(false)}
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Fechar"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className="modal-body">
            {/* Renderiza a Tabela de Logs */}
            <TableLogConsultoriaProjeto />
          </div>
          <div className="modal-footer">
            <button
              onClick={() => setAbrirModal(false)}
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ModalLogConsultoria }
