/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { IApresentarDimensaoModel, IDadosGraficosParticipanteModel } from '../helpers/AnaliseResultadoHelper'
import Chart from 'react-apexcharts'

type Props = {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  isMobile: boolean
  idDimensao: number
  dadosGraficosParticipante: IDadosGraficosParticipanteModel | undefined
}

const ModalGraficoMediaGeralPorParticipante = ({showModal, setShowModal, isMobile, idDimensao, dadosGraficosParticipante}: Props) => {
  let [dimensao, setDimensao] = useState<IApresentarDimensaoModel | undefined>(undefined)  

  useEffect(() => {

    if (idDimensao > 0) setDimensao(dadosGraficosParticipante?.Dimensoes.find(x => x.Id == idDimensao) ?? undefined)
    else setDimensao(undefined)

  }, [idDimensao])

  
  useEffect(() => {

    if (showModal && idDimensao > 0) setDimensao(dadosGraficosParticipante?.Dimensoes.find(x => x.Id == idDimensao) ?? undefined)
    else setDimensao(undefined)

  }, [showModal])

  const escala: any[] = [];
  if (dadosGraficosParticipante?.Escala) {
    for (let i = dadosGraficosParticipante.Escala.Inicio ?? 0; i <= (dadosGraficosParticipante.Escala.Fim! ?? 10); i++) {
      escala.push(
        <option key={i} value={i} label={i.toString()} />
      );
    }
  }

  let graficoDimensao = dadosGraficosParticipante?.GraficoMediaPorDimensao.find(x => x.IdDimensao == idDimensao);

  let options = {              
    labels: graficoDimensao?.Labels ?? [],
    series: graficoDimensao?.Series ?? [],
    colors: graficoDimensao?.Colors ?? [],
    plotOptions: {
      radialBar: {
        startAngle: -180,
        endAngle: 180,
        track: {
          background: '#f4f4f4',
          startAngle: -180,
          endAngle: 180,
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            fontSize: '30px',
            color: '#3e875f',
            show: true,
            formatter: function (val: number) {
              const escalaMaxima = dadosGraficosParticipante?.Escala.Fim ?? 10;
              const valorAjustado = ((val * escalaMaxima) / 100).toFixed(2);
              return valorAjustado;
            }
          },
        }
      }
    },
  }
    
  return (
    <div className={`modal ${showModal? "show" : ""} fade`} tabIndex={-1} id="kt_modal_1" style={{display: `${showModal? "block" : "none"}`}}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
            <div className="modal-content border border-1 border-success">
            <div className="modal-header bg-success">
                <h3 className="modal-title">{dimensao?.Nome ?? ''}</h3>
                <div
                  onClick={() => setShowModal(false)}
                  className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                  data-bs-dismiss="modal"
                  aria-label="Fechar"
                >
                  <KTSVG
                      path="/media/icons/duotune/arrows/arr061.svg"
                      className="svg-icon svg-icon-2x"
                  />
                </div>
            </div>
            <div className="modal-body">
              {dimensao && dimensao.Perguntas.map((pergunta, perguntaIndex) => {

                return (
                  <div key={`accordion-pergunta-${pergunta.Id}`} id={`accordion-pergunta-${pergunta.Id}`} className={`w-100 ${perguntaIndex < ((dimensao?.Perguntas.length ?? 1) - 1) ? 'mb-8' : ''}`}>
                    <div className={`card card-custom card-pergunta border border-secondary border-1 shadow-sm dimensao-pesquisa-nao-selecionado`}>
                      <div className="card-header border-bottom border-secondary border-1 rounded-bottom flex-nowrap" id={`card-pergunta-header-${pergunta.Id}`} style={{ backgroundColor: 'rgba(218, 218, 217, 1)' }}>
                        <div className="card-title">
                          <h5 className=''>{(pergunta.Texto)}</h5>                                
                        </div>
                        <div className="card-toolbar w-md-auto">                                
                          <button type="button" className="btn btn-icon btn-sm btn-accordion btn-light" data-bs-toggle="collapse" data-bs-target={`#card-pergunta-collapse-${pergunta.Id}`} aria-expanded="false" aria-controls={`card-pergunta-collapse-${pergunta.Id}`} >
                            <i className='fas fa-arrow-down'></i>
                            <i className='fas fa-arrow-up'></i>
                          </button>                   
                        </div>
                      </div>
                      <div id={`card-pergunta-collapse-${pergunta.Id}`} className="collapse" aria-labelledby={`card-pergunta-header-${pergunta.Id}`} data-bs-parent={`#accordion-pergunta-${pergunta.Id}`}>
                        <div className={`card-body w-100 ${isMobile ? 'p-2' : ''}`}>

                          <datalist className='pesquisa-datalist w-100' id={`input-range-pergunta-${pergunta.Id}`}>
                            {escala}
                          </datalist>
                          <input 
                            type="range"
                            min={dadosGraficosParticipante?.Escala.Inicio ?? 0}
                            max={dadosGraficosParticipante?.Escala.Fim ?? 10}
                            step="1"
                            className={`pesquisa-input-range ${pergunta.Resposta ? 'pesquisa-input-range-com-resposta' : 'pesquisa-input-range-sem-resposta'} form-range w-100`}
                            list={`input-range-pergunta-${pergunta.Id}`}
                            value={pergunta.Resposta ?? ''}
                            disabled={true}
                          />  
                          
                        </div>   
                      </div>                        
                    </div>
                  </div>
                )
              })}
              <h3 className='text-center mt-7'>Média da dimensão</h3>
              <div className='col-lg-12'>
                <Chart options={options} series={options.series} type="radialBar" height={'400px'} />   
              </div>
            </div>
            <div className={`modal-footer d-flex justify-content-end`}>                        
              <button
                onClick={() => setShowModal(false)}
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Fechar
              </button>
            </div>
            </div>
        </div>
    </div>
  )
}

export {ModalGraficoMediaGeralPorParticipante}