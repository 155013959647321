/* eslint-disable react/jsx-no-target-blank */
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'
import {EnumServicoTipoPlano} from '../../../../../app/modules/services/core/_enums'

const SidebarMenuMain = () => {
  const {auth} = useAuth()
  const servico = auth?.servico
  const {moduloDescricaoConfig, moduloPesquisaConfig} = servico!

  return (
    <>
      <SidebarMenuItem
        to='/inicio'
        icon='/media/icons/duotune/general/gen001.svg'
        title='Início'
        fontIcon='bi-app-indicator'
      />

      {auth?.roles.find((x) => x === 'Administrador') !== undefined && (
        <>
          <SidebarMenuItemWithSub
            to=''
            title='Administração'
            fontIcon='fas fa-pencil-ruler'
            
          >

            <SidebarMenuItem to='/administracao/empresas' title='Empresas' hasBullet={true} />

            <SidebarMenuItem to='/administracao/relatorio' title='Relatório' hasBullet={true} />

            <SidebarMenuItem to='/administracao/prompts' title='Prompts' hasBullet={true} />

          </SidebarMenuItemWithSub>
        </>
      )}

      <>
        {/* Esse cara aqui vai sumir por enquanto */}
        {/* <SidebarMenuItem
          to='/builder'
          icon='/media/icons/duotune/general/gen019.svg'
          title='Layout Builder'
          fontIcon='bi-layers'
        /> */}
        {(auth?.roles.find((x) => x === 'Master') !== undefined ||
          auth?.roles.find((x) => x === 'Administrador') !== undefined ||
          auth?.permissoes.find(
            (x) =>
              x === 'D35C8D7C5D36798171037211EDD26ECC' ||
              x === 'D3839E80EC2C42BBEF34E55F754BB33C' ||
              x === '1294D4AC190149DFB635279F699672C8' ||
              x === '5742599FD4095A48247B0DF529E961F1'
          ) !== undefined) &&
          servico?.tipoPlano === EnumServicoTipoPlano.Buy && (
            <>
            <SidebarMenuItemWithSub
              to=''
              title='Gerais'
              icon='/media/icons/duotune/coding/cod001.svg'
            >
              {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                auth?.permissoes.find((x) => x === 'D35C8D7C5D36798171037211EDD26ECC') !==
                  undefined) && (
                <SidebarMenuItem to='/configuracao/empresa' title='Empresa' hasBullet={true} />
              )}

              {((auth?.roles.find((x) => x === 'Master') !== undefined &&
                auth?.servico.AutoGerenciarPerfis) ||
                auth?.roles.find((x) => x === 'Administrador') !== undefined) && (
                <SidebarMenuItem
                  to='/configuracao/perfis'
                  title='Perfis'
                  hasBullet={true}
                />
              )}

              {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                auth?.permissoes.find((x) => x === 'D3839E80EC2C42BBEF34E55F754BB33C') !==
                  undefined) && (
                <SidebarMenuItem
                  to='/configuracao/niveis-hierarquicos'
                  title='Níveis Hierárquicos'
                  hasBullet={true}
                />
              )}

              {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                auth?.permissoes.find((x) => x === '1294D4AC190149DFB635279F699672C8') !==
                  undefined) && (
                <SidebarMenuItem to='/configuracao/areas' title='Áreas' hasBullet={true} />
              )}

              {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                auth?.permissoes.find((x) => x === '5742599FD4095A48247B0DF529E961F1') !==
                  undefined) && (
                <SidebarMenuItem to='/configuracao/cargos' title='Cargos' hasBullet={true} />
              )}

              {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                auth?.permissoes.find((x) => x === 'F09BC7725A3BCB24201548FABE70AA63') !==
                  undefined) && (
                <SidebarMenuItem
                  to='/configuracao/colaboradores'
                  title='Colaboradores'
                  hasBullet={true}
                />
              )}
            </SidebarMenuItemWithSub>
            </>
          )}


        {servico?.moduloConfig.DescricaoDeCargo && moduloDescricaoConfig?.ativo && (
          <>
            <SidebarMenuItemWithSub
              to=''
              title='Descrição de Cargos'
              icon='/media/icons/duotune/text/txt001.svg'
            >                   
              {servico?.clienteConsultoriaDescricao != null ? (
                <>
                  {(servico?.tipoPlano === EnumServicoTipoPlano.Try ||
                    auth?.roles.find((x) => x === 'Master') !== undefined ||
                    auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                    auth?.permissoes.find((x) => x === 'F1DD4A87E0981CE7EBF8749B6F1C6964') !== undefined) && (
                    <SidebarMenuItem to='/descricao/dashboard' title='Dashboard' hasBullet={true} />
                  )}
                  
                  {(((auth?.permissoes.find((x) => x === 'B847D84BCACD0DF5405CCE536A99AD58') === undefined) && (auth?.permissoes.find((x) => x === 'F83AC0F344DD8AFE835ABD3DF11092A8') === undefined)) || (auth?.permissoes.find((x) => x === '410F06772E4A9186E4ED3D687CF241A0') !== undefined) === true)
                  ? 
                    <>
                      {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                        auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                        auth?.permissoes.find((
                          x) => x === 'F1DD4A87E0981CE7EBF8749B6F1C6964') !==
                          undefined) && (
                        <SidebarMenuItem
                          to='/descricao/a-revisar'
                          title='Descrições a Revisar'
                          hasBullet={true}
                        />
                      )}
                      {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                        auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                        auth?.permissoes.find(
                          (x) => x === 'F1DD4A87E0981CE7EBF8749B6F1C6964'
                        ) !== undefined) && (
                        <SidebarMenuItem
                          to='/descricao/a-avaliar'
                          title='Descrições a Avaliar'
                          hasBullet={true}
                        />
                      )}
                      {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                        auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                        auth?.permissoes.find(
                          (x) => x === 'F1DD4A87E0981CE7EBF8749B6F1C6964'
                        ) !== undefined) && (
                        <SidebarMenuItem
                          to='/descricao/a-aprovar'
                          title='Descrições a Aprovar'
                          hasBullet={true}
                        />
                      )}
                      {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                        auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                        auth?.permissoes.find(
                          (x) => x === 'F1DD4A87E0981CE7EBF8749B6F1C6964'
                        ) !== undefined) && (
                        <SidebarMenuItem
                          to='/descricao/reprovadas'
                          title='Descrições Reprovadas'
                          hasBullet={true}
                        />
                      )}
                      {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                        auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                        auth?.permissoes.find(
                          (x) => x === 'F1DD4A87E0981CE7EBF8749B6F1C6964'
                        ) !== undefined) && (
                        <SidebarMenuItem
                          to='/descricao/descricoes'
                          title='Descrições Aprovadas'
                          hasBullet={true}
                        />
                      )}
                    </>
                  : 
                      <>

                        {/* avaliador */}
                        {(auth?.permissoes.find((x) => x === 'F83AC0F344DD8AFE835ABD3DF11092A8') !== undefined) && (
                          <>
                            {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                              auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                              auth?.permissoes.find(
                                (x) => x === 'F1DD4A87E0981CE7EBF8749B6F1C6964'
                              ) !== undefined) && (
                              <SidebarMenuItem
                                to='/descricao/a-avaliar'
                                title='Descrições a Avaliar'
                                hasBullet={true}
                              />
                            )}
                          </>
                        )}

                        {/* aprovador */}
                        {(auth?.permissoes.find((x) => x === 'B847D84BCACD0DF5405CCE536A99AD58') !== undefined) && (
                          <>
                            {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                              auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                              auth?.permissoes.find(
                                (x) => x === 'F1DD4A87E0981CE7EBF8749B6F1C6964'
                              ) !== undefined) && (
                              <SidebarMenuItem
                                to='/descricao/a-aprovar'
                                title='Descrições a Aprovar'
                                hasBullet={true}
                              />
                            )}
                          </>
                        )}
                      </>
                  }

                </>
              ):(
                <>
                  <SidebarMenuItem 
                    to='/descricao/inicio' 
                    title='Início' 
                    hasBullet={true}
                  /> 
                  {servico?.tipoPlano === EnumServicoTipoPlano.Try && (
                    <>
                      <SidebarMenuItem
                        to='/descricao/configuracoes-iniciais'
                        title='Configurações Iniciais'
                        hasBullet={true}
                      />
                    </>
                  )}
                  {(servico?.tipoPlano === EnumServicoTipoPlano.Try ||
                    auth?.roles.find((x) => x === 'Master') !== undefined ||
                    auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                    auth?.permissoes.find((x) => x === '8732C0477F905BC737602894E552327B') !==
                      undefined) && 
                  (
                    <SidebarMenuItem
                      to='/descricao/processos'
                      title='Processos'
                      hasBullet={true}
                    />
                  )}     
                  {moduloDescricaoConfig?.Desbloqueado && (
                    <>
                      <SidebarMenuItemWithSub
                        to=''
                        title='Acervo de Descrições'
                        hasBullet={true}
                      >
                        {(servico?.tipoPlano === EnumServicoTipoPlano.Try ||
                          auth?.roles.find((x) => x === 'Master') !== undefined ||
                          auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                          auth?.permissoes.find((x) => x === '92374908B8E104F71A26BBE0E0126AD2') !==
                            undefined) && (
                          <SidebarMenuItem to='/descricao/dashboard' title='Dashboard' hasBullet={true} />
                        )}
      
                        {servico?.tipoPlano === EnumServicoTipoPlano.Buy ? (
                          <>
                            {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                              auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                              auth?.permissoes.find((x) => x === '217D6C1F64B82F49D78769A987D30602') !==
                                undefined) && (
                              <SidebarMenuItem
                                to='/descricao/modelo-formulario-descricao'
                                title='Modelo de Formulário'
                                hasBullet={true}
                              />
                            )}
                            {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                              auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                              auth?.permissoes.find((x) => x === 'A1BDDCEDA1A21ED08B4A6D1A24B81E9D') !==
                                undefined) && (
                              <SidebarMenuItem
                                to='/descricao/a-revisar'
                                title='Descrições a Revisar'
                                hasBullet={true}
                              />
                            )}
                            {servico.moduloDescricaoConfig.usaAprovacao ? (
                              <>
                                {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                  auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                  auth?.permissoes.find(
                                    (x) => x === 'E6A49A365543B198BB13D8C46C98F9B6'
                                  ) !== undefined) && (
                                  <SidebarMenuItem
                                    to='/descricao/a-aprovar'
                                    title='Descrições a Aprovar'
                                    hasBullet={true}
                                  />
                                )}
                                {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                  auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                  auth?.permissoes.find(
                                    (x) => x === '86F360E465721DC0D09E2CD0F31FFBBF'
                                  ) !== undefined) && (
                                  <SidebarMenuItem
                                    to='/descricao/reprovadas'
                                    title='Descrições Reprovadas'
                                    hasBullet={true}
                                  />
                                )}
                                {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                  auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                  auth?.permissoes.find(
                                    (x) => x === '31EF35476F392CA688A004B45C4E153B'
                                  ) !== undefined) && (
                                  <SidebarMenuItem
                                    to='/descricao/descricoes'
                                    title='Descrições Aprovadas'
                                    hasBullet={true}
                                  />
                                )}
                                {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                  auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                  auth?.permissoes.find(
                                    (x) => x === '780C5D303B40864B28FFA3BEB37D9FCE'
                                  ) !== undefined) && (
                                  <SidebarMenuItem
                                    to='/descricao/historico'
                                    title='Histórico'
                                    hasBullet
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                  auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                  auth?.permissoes.find(
                                    (x) => x === '31EF35476F392CA688A004B45C4E153B'
                                  ) !== undefined) && (
                                  <SidebarMenuItem
                                    to='/descricao/descricoes'
                                    title='Descrições Aprovadas'
                                    hasBullet={true}
                                  />
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <SidebarMenuItem to='/descricao/revisar' title='Revisar' hasBullet={true} />
                            <SidebarMenuItem
                              to='descricao/descricoes'
                              title='Descrições Aprovadas'
                              hasBullet={true}
                            />
                          </>
                        )}
                      </SidebarMenuItemWithSub> 

                      {servico?.tipoPlano === EnumServicoTipoPlano.Buy && (
                        <>
                          {(servico?.moduloDescricaoConfig.Consultoria) && (
                            <SidebarMenuItemWithSub
                              to=''
                              title='Consultoria'
                              hasBullet={true}
                            >
                              <SidebarMenuItem to='/descricao/consultoria/dashboard' title='Dashboard' hasBullet={true} />
                              {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                auth?.roles.find((x) => x === 'Administrador') !== undefined || 
                                (auth?.permissoes.find(x => x === '6CD7B6C08D4159B14F07D18889E55520') !== undefined)) && (
                                <SidebarMenuItem 
                                  to='/descricao/consultoria/cliente' 
                                  title='Clientes' 
                                  hasBullet={true} 
                                />
                              )}
                              {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                auth?.roles.find((x) => x === 'Administrador') !== undefined ||
                                auth?.permissoes.find((x) => x === '950824CA8E8B19605779BA7774E224B5') !== undefined) && (
                                <SidebarMenuItem
                                  to='/descricao/consultoria/colaborador'
                                  title='Colaboradores'
                                  hasBullet={true}
                                />
                              )} 
                              {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                auth?.roles.find((x) => x === 'Administrador') !== undefined || 
                                (auth?.permissoes.find(x => x === 'AED88DD4985351B464E9D4DF896B7BFB') !== undefined)) && (
                                <SidebarMenuItem
                                  to='/descricao/consultoria/consultor'
                                  title='Consultores'
                                  hasBullet={true}
                                />
                              )}
                              {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                auth?.roles.find((x) => x === 'Administrador') !== undefined || 
                                (auth?.permissoes.find(x => x === 'D089461D10267A2E4FF1C9F76C2DA9C2') !== undefined)) && (
                                <SidebarMenuItem
                                  to='/descricao/consultoria/modelo-formulario-descricao'
                                  title='Modelo de Formulário'
                                  hasBullet={true}
                                /> 
                              )}                           
                              {((auth?.roles.find(x => x === 'Master') !== undefined) || 
                                (auth?.roles.find(x => x === 'Administrador') !== undefined) || 
                                (auth?.permissoes.find(x => x === '4BFB2976419C5EF8AEB67794BC84E777') !== undefined)) && (
                                <SidebarMenuItem
                                  to='/descricao/consultoria/niveis-hierarquicos'
                                  title='Níveis hierárquicos'
                                  hasBullet={true}
                                /> 
                              )}
                              {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                auth?.roles.find((x) => x === 'Administrador') !== undefined || 
                                (auth?.permissoes.find(x => x === '1B0EEDDAFFD79CD502A53E4AFBCB58D5') !== undefined)) && (
                                <SidebarMenuItem
                                  to='/descricao/consultoria/areas'
                                  title='Áreas'
                                  hasBullet={true}
                                /> 
                              )}
                              {(auth?.roles.find((x) => x === 'Master') !== undefined ||
                                auth?.roles.find((x) => x === 'Administrador') !== undefined || 
                                (auth?.permissoes.find(x => x === '853B13ADF2956A676ED39A32E63017C1') !== undefined)) && (
                                <SidebarMenuItem
                                  to='/descricao/consultoria/cargos'
                                  title='Cargos'
                                  hasBullet={true}
                                /> 
                              )}                                 
                            </SidebarMenuItemWithSub>
                          )}
                        </>
                      )}
                    </>
                  )}         
                </>
              )}
            </SidebarMenuItemWithSub>
          </>
        )}
        {servico?.moduloConfig.Pesquisa && moduloPesquisaConfig.ativo && (
          <SidebarMenuItemWithSub
            to=''
            title='Pesquisa'
            icon='/media/icons/duotune/graphs/gra010.svg'
          >
            <SidebarMenuItem to='/pesquisa-ia/inicio' title='Início' hasBullet={true} />
            {(servico?.tipoPlano === EnumServicoTipoPlano.Try ||
              auth?.roles.find((x) => x === 'Master') !== undefined ||
              auth?.roles.find((x) => x === 'Administrador') !== undefined ||
              auth?.permissoes.find((x) => x === '52CA6BCD3B0C506D925D7A05F5CE9376') !==
                undefined) && (
              <SidebarMenuItem
                to='/pesquisa-ia/configuracoes-iniciais'
                title='Configurações Iniciais'
                hasBullet={true}
              />
            )}
            {(servico?.tipoPlano === EnumServicoTipoPlano.Try ||
              auth?.roles.find((x) => x === 'Master') !== undefined ||
              auth?.roles.find((x) => x === 'Administrador') !== undefined ||
              auth?.permissoes.find((x) => x === '7576890D4CA4472D3DD78EAD3FE317F0') !==
                undefined) && (
              <SidebarMenuItem to='/pesquisa-ia/dashboard' title='Dashboard' hasBullet={true} />
            )}
            {(servico?.tipoPlano === EnumServicoTipoPlano.Buy && ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '72FF3D60CA5D0F6E20EAAC12A9B91BB4')) !== undefined)) && (         
              <SidebarMenuItem
                to='/pesquisa-ia/temas'
                title='Temas'
                hasBullet={true}
              />
            )}
            {(servico?.tipoPlano === EnumServicoTipoPlano.Buy && ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '16EBEFF6AF21C77FCF990EE1FC11DBD9')) !== undefined)) && (         
              <SidebarMenuItem
                to='/pesquisa-ia/escalas'
                title='Escalas'
                hasBullet={true}
              />
            )}
            <SidebarMenuItem
              to='/pesquisa-ia/pesquisa-em-andamento'
              title='Pesquisa em andamento'
              hasBullet={true}
            />
            <SidebarMenuItemWithSub
              to=''
              title='Analisar dados'
              hasBullet={true}
            >
              {((servico?.tipoPlano === EnumServicoTipoPlano.Try) || (auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'FE0760BF2063B496A862291596BC552C') !== undefined)) && (         
                <SidebarMenuItem
                  to='/pesquisa-ia/analise-resultado/por-pesquisa'
                  title='Por Pesquisa'
                  hasBullet={true}
                />
              )} 
              {((servico?.tipoPlano === EnumServicoTipoPlano.Try) || (auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '45BA682962BDF1BD095E21672BA621DE') !== undefined)) && (         
                <SidebarMenuItem
                  to='/pesquisa-ia/analise-resultado/proprio'
                  title='Próprio'
                  hasBullet={true}
                />
              )} 
              {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'C75659958306F0D526608C9C79D72B9C') !== undefined)) && (         
                <SidebarMenuItem
                  to='/pesquisa-ia/analise-resultado/liderados'
                  title='Liderados'
                  hasBullet={true}
                />
              )} 
            </SidebarMenuItemWithSub>
            {(servico?.tipoPlano === EnumServicoTipoPlano.Buy) && (
              <>
                <SidebarMenuItemWithSub
                  to=''
                  title='Analisar resultados'
                  hasBullet={true}
                >
                  {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'A8B1E40269C56AD04420505CE124AF25') !== undefined)) && (         
                    <SidebarMenuItem
                      to='/pesquisa-ia/resultados-globais'
                      title='Resultados Globais'
                      hasBullet={true}
                    />
                  )} 
                  {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '816E0E82917C685F73BB366C2ED76B8C') !== undefined)) && (         
                    <SidebarMenuItem
                      to='/pesquisa-ia/resultados-por-dimensao'
                      title='Resultados Por Dimensão'
                      hasBullet={true}
                    />
                  )} 
                  {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '10077E63ED95C4370BEC30B9D5FA49B9') !== undefined)) && (         
                    <SidebarMenuItem
                      to='/pesquisa-ia/resultados-por-questao'
                      title='Resultados Por Questão'
                      hasBullet={true}
                    />
                  )} 
                  {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '028CFBF5E2617367DB36F7E4DC1E2EDF') !== undefined)) && (         
                    <SidebarMenuItem
                      to='/pesquisa-ia/participantes-por-categoria'
                      title='Participantes Por Categoria'
                      hasBullet={true}
                    />
                  )} 
                  {((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === 'D509A704DE38592C4A88C4318F6D3C70') !== undefined)) && (         
                    <SidebarMenuItem
                      to='/pesquisa-ia/globais-por-pergunta'
                      title='Globais Por Pergunta'
                      hasBullet={true}
                    />
                  )} 
                </SidebarMenuItemWithSub>
              </>
            )} 
            {(servico?.tipoPlano === EnumServicoTipoPlano.Try || ((auth?.roles.find(x => x === 'Master') !== undefined) || (auth?.roles.find(x => x === 'Administrador') !== undefined) || (auth?.permissoes.find(x => x === '2F68A173F343791A25FD1528E2BEE0FF') !== undefined) || (auth?.permissoes.find(x => x === '073FAC45874CED7E74E3D0CFA8ACBDA6') !== undefined))) && (         
              <SidebarMenuItem
                to='/pesquisa-ia/exportar-resultados'
                title='Exportar Resultados'
                hasBullet={true}
              />
            )}           
          </SidebarMenuItemWithSub>
        )}


        <SidebarMenuItemWithSub
          to=''
          title='Tutorial'
          icon='/media/prolead/svg/question-octagon.svg'
        >
          {(servico?.tipoPlano === EnumServicoTipoPlano.Try ||
            auth?.roles.find((x) => x === 'Master') !== undefined ||
            auth?.roles.find((x) => x === 'Administrador') !== undefined ||
            auth?.permissoes.find((x) => x === '9C374241FF1E5715026B0FDE04A5E870') !==
              undefined) && (
            <SidebarMenuItem
              to='/conceitos/ajuda'
              title='Conceitos sobre descrição de cargos'
              hasBullet={true}
            />
          )}
          {(servico?.tipoPlano === EnumServicoTipoPlano.Try ||
            auth?.roles.find((x) => x === 'Master') !== undefined ||
            auth?.roles.find((x) => x === 'Administrador') !== undefined ||
            auth?.permissoes.find((x) => x === 'B60EE4361CAD2C6C9DCE601B8CDF200E') !==
              undefined) && (
            <SidebarMenuItem
              to='/ajuda/tutorial'
              title='Como navegar no sistema'
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      </>
    </>
  )
}

export {SidebarMenuMain}
