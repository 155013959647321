import { NumberFormatNotation } from '@formatjs/ecma402-abstract'
import * as Yup from 'yup'
import { EnumServicoTipoPlano } from '../../../../modules/services/core/_enums'
import { useValidation } from '../../../../modules/services/Bundle'

export interface IModuloDescricaoConfig {
  idServico: number
  ativo: boolean
  usaAprovacao: boolean
  Consultoria: boolean
  QtdMaximaCargos: number
}

const defaultModuloDescricaoConfig: IModuloDescricaoConfig = {
  idServico: 0,
	ativo: false,
  usaAprovacao: false,
  Consultoria: false,
  QtdMaximaCargos: 10
}

export interface IModuloPesquisaConfig {
  idServico: number
  ativo: boolean
  QtdMaximaTemas: number
  QtdMaximaPesquisas: number
  QtdMaximaParticipantes: number
}


const defaultModuloPesquisaConfig: IModuloPesquisaConfig = {
  idServico: 0,
	ativo: false,
  QtdMaximaTemas: 3,
  QtdMaximaPesquisas: 3,
  QtdMaximaParticipantes: 10
}

export interface IUsuarioModel {
  Nome: string,
  Sobrenome: string,
  Email: string,
  Telefone: string,
  Password: string,
  ConfirmPassword: string,
}

const initialValuesUsuario:IUsuarioModel = {
  Nome: '',
  Sobrenome: '',
  Email: '',
  Telefone: '',
  Password: '',
  ConfirmPassword: '',
}

export interface IEmpresaParamsModel {  
  idEmpresa: number
  tipoPlano: number
  QtdMaximaUsuarios: number
  PeriodoAcessoDataInicial: string
  PeriodoAcessoDataFinal: string
  AutoGerenciarPerfis: boolean
  limparDadosVersaoTry: boolean
  idUsuarioMaster?: number
  moduloDescricaoConfig: IModuloDescricaoConfig
  moduloPesquisaConfig: IModuloPesquisaConfig
}

const initValuesParams: IEmpresaParamsModel = {
  idEmpresa: 0,
  tipoPlano: EnumServicoTipoPlano.Try,
  QtdMaximaUsuarios: 5,
  PeriodoAcessoDataInicial: '',
  PeriodoAcessoDataFinal: '',
  AutoGerenciarPerfis: false,
  limparDadosVersaoTry: false,
  idUsuarioMaster: undefined,
  moduloDescricaoConfig: defaultModuloDescricaoConfig,
  moduloPesquisaConfig: defaultModuloPesquisaConfig,
}

export interface IEmpresaModel {  
  Id: number
  RazaoSocial: string
  NomeFantasia: string
  Cnpj: string
  IdSegmento?: number
  NomeSegmento: string
  Telefone: string
  Celular: string
  Site: string
  EmpresaLogo: any
  EmpresaEndereco: []
  QtdFuncionarios: number
  PorteEmpresa: number
  Servico: IEmpresaParamsModel, 
  Representante: IUsuarioModel,
  EmpresaDominio: []
}

const initValues: IEmpresaModel = {
  Id: 0,
  RazaoSocial: '',
  NomeFantasia: '',
  Cnpj: '',
  IdSegmento: undefined,
  NomeSegmento: '',
  Telefone: '',
  Celular: '',
  Site: '',
  EmpresaLogo: undefined,
  EmpresaEndereco: [],
  QtdFuncionarios: 0,
  PorteEmpresa: 0,
  Servico: initValuesParams,
  Representante: initialValuesUsuario,
  EmpresaDominio: []
}

const defaultValidationSchemas = Yup.object().shape({
  RazaoSocial: Yup.string().required('A razão social é obrigatória'),
  IdSegmento: Yup.string().required('O segmento de mercado é obrigatório'),
  EmpresaDominio: Yup.array().min(1, 'Deve ter pelo menos um domínio'),
  NomeSegmento: Yup.string().when('IdSegmento', {
    is: '2',
    then: Yup.string().nullable()
    .required('O nome do segmento é obrigatório')
    .matches(/^[a-zA-Z\sáÁàÀâÂãÃéÉèÈêÊíÍìÌîÎóÓòÒôÔõÕúÚùÙûÛüÜñÑçÇ]*$/, 'O nome do segmento não pode conter números, caracteres especiais ou espaços consecutivos')
    .matches(/^(?!(.*\s){3})/, 'O nome do segmento contém muitos espaços consecutivos'),
    otherwise: Yup.string().nullable()
    .matches(/^[a-zA-Z\sáÁàÀâÂãÃéÉèÈêÊíÍìÌîÎóÓòÒôÔõÕúÚùÙûÛüÜñÑçÇ]*$/, 'O nome do segmento não pode conter números, caracteres especiais ou espaços consecutivos')
    .matches(/^(?!(.*\s){3})/, 'O nome do segmento contém muitos espaços consecutivos'),
  }),
  QtdFuncionarios: Yup.number().min(0, "A quantidade não pode ser negativa."), 
  Servico : Yup.object().shape({
    QtdMaximaUsuarios: Yup.string().required('A quantidade máxima de usuários é obrigatória'),  
    PeriodoAcessoDataInicial: Yup.date()
    .required("O período inicial é obrigatório")
    .typeError("O período inicial é obrigatório")
    .nullable(),
    PeriodoAcessoDataFinal: Yup.date()
    .when(
      "PeriodoAcessoDataInicial",
      (PeriodoAcessoDataInicial, schema) => PeriodoAcessoDataInicial 
      && schema != null && schema.min(PeriodoAcessoDataInicial, 'Data final deve ser maior que a data inicial')
    )
    .required("O período final é obrigatório")
    .typeError("O período final é obrigatório")
    .nullable(),
    moduloDescricaoConfig: Yup.object().shape({
      QtdMaximaCargos: Yup.string().required('A quantidade máxima de cargos é obrigatória'),
    }),
    moduloPesquisaConfig: Yup.object().shape({
      QtdMaximaTemas: Yup.string().required('A quantidade máxima de temas é obrigatória'),
      QtdMaximaPesquisas: Yup.string().required('A quantidade máxima de pesquisas é obrigatória'),
      QtdMaximaParticipantes: Yup.string().required('A quantidade máxima de participantes é obrigatória'),
    }),
  }),
  Representante: Yup.object().shape({
    Nome: Yup.string()
    .min(3, 'O nome deve ter no mínimo 3 caracteres')
    .max(50, 'O nome deve ter no máximo 50 caracteres')
    .required('O nome é obrigatório'),
    Sobrenome: Yup.string()
      .min(3, 'O sobrenome deve ter no mínimo 3 caracteres')
      .max(50, 'O sobrenome deve ter no máximo 50 caracteres')
      .required('O sobrenome é obrigatório'),
    Email: Yup.string()
    .email('E-mail inválido')
    .test('company-email', 'O e-email deve ser corporativo', (val = '') => {
      if (!val) return true
      const validation = useValidation()
      return validation.CompanyEmail(val)
    })
    .min(3, 'O e-mail deve ter no mínimo 3 caracteres')
    .max(80, 'O e-mail deve ter no máximo 80 caracteres')
    .required('O e-mail é obrigatório'),
    Telefone: Yup.string()
    .test('Telefone', 'Telefone inválido', (val = '') => {
      if (!val) return true
      return val.replace(/\D/g, '').length === 11
    })
    .required('O telefone é obrigatório'),
    Password: Yup.string()
    .min(8, 'A senha deve ter no mínimo 8 caracteres')
    .max(20, 'A senha deve ter no máximo 20 caracteres')
    // eslint-disable-next-line
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#!?%\-\=\\\/\[\]\{\}\(\)\_\+\.\,])[0-9a-zA-Z$*&@#!?%\-\=\\\/\[\]\{\}\(\)\_\+\.\,]{8,20}$/,
      'A senha deve ter pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 símbolo'
    )
    .required('A senha é obrigatória'),
    ConfirmPassword: Yup.string()
    .required('A confirmação de senha é obrigatória')
    .when('Password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('Password')],
        'A senha e a confirmação de senha não conferem'
      ),
    }),
  })
    
  })

export {defaultValidationSchemas, initValues, defaultModuloDescricaoConfig, defaultModuloPesquisaConfig}
